<template>
  <section class="page_record">
    <header>
      <el-form :inline="true" :model="formSearch" class="demo-form-inline" size="mini">
        <el-form-item label="人力公司">
          <el-select clearable v-model="formSearch.ocompanyId" placeholder="请选择">
            <el-option
                    v-for="item in ocompanyNamelist"
                    :key="item.ocompanyId"
                    :label="item.ocompanyName"
                    :value="item.ocompanyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交易日期">
          <el-date-picker
                  clearable
                  v-model="applyDate"
                  type="datetimerange"
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="交易类型">
          <el-select clearable v-model="formSearch.type" placeholder="请选择">
            <el-option :value="1" label="即时到账" />
            <el-option :value="2" label="主动提取" />
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select clearable v-model="formSearch.payState" placeholder="请选择">
            <el-option :value="0" label="提现中" />
            <el-option :value="1" label="提现成功" />
            <el-option :value="2" label="提现失败" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="formSearchSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </header>
    <template v-if="tableDatelist.length">
      <!-- 表格开始 -->
      <el-table :data="tableDatelist" stripe style="width: 100%">
        <el-table-column prop="ocompanyOName" label="人力公司" min-width="150"/>
        <el-table-column prop="createDate" label="交易日期" min-width="120" />
        <el-table-column prop="type" label="交易类型" min-width="120" >
          <template v-slot="scope">
            <el-link  v-if="scope.row.type === 1">即时到账</el-link>
            <el-link  v-if="scope.row.type === 2">主动提取</el-link>
          </template>
        </el-table-column>>
        <el-table-column prop="amount" label="金额" min-width="120"/>
        <el-table-column prop="payState" label="状态" min-width="120" >
          <template v-slot="scope">
            <el-link  v-if="scope.row.payState === 0">提现中 </el-link>
            <el-link  v-else-if="scope.row.payState === 1">提现成功</el-link>
            <el-link  v-else-if="scope.row.payState === 2">提现失败</el-link>
            <el-link  v-else>无</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="payDescription" label="描述" min-width="120" />
      </el-table>
      <!-- 表格结束 -->
      <pagination :listLoading="listLoading" @getList="getList" @setLimit="formSearch.size = $event" @setPage="formSearch.current = $event" :currentPage="formSearch.current" :pageSize="formSearch.size" :total="total"></pagination>

    </template>
    <template v-else>
      <empty text="暂无交易记录"></empty>
    </template>
  </section>
</template>

<script>
import api from "@/api/list";
import empty from "@/components/empty/index.vue";
import pagination from "@/components/pagination/index.vue";
export default {
  components: {
    empty,
    pagination,
  },
  data() {
    return {
      //人力公司列表
      ocompanyNamelist: null,
      //申请时间
      applyDate: [],
      total: null,

      listLoading: false,

      formSearch: {
          ocompanyId: "",
          type: "",
          payState: "",
          operationStartDate: "",
          operationEndDate: "",
          current: 1,
          size: 10,
      },
      tableDatelist: [],
    };
  },
  created() {
    //初始化人力公司下拉框
    this.initOcompanyList()
    //
    this.getList()
  },
  methods: {
    initOcompanyList(){
        api.getOcompanyNameByAccount().then((res) => {
           this.ocompanyNamelist = res.data;
        });
    },
    //查询
    formSearchSubmit() {
      this.formSearch.current = 1;
      this.getList();
    },
    getList() {
        this.listLoading = true;
        if (
            this.applyDate !== null &&
            this.applyDate.length === 2
        ) {
            this.formSearch.operationStartDate = this.applyDate[0];
            this.formSearch.operationEndDate = this.applyDate[1];
        } else {
            this.formSearch.operationStartDate = null;
            this.formSearch.operationEndDate = null;
        }
        api.getRecordList(this.formSearch).then((response) => {
            this.tableDatelist = response.data.records;
            this.total = response.data.total;
            this.listLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page_record {
  padding-top: 1em;
}
.el-range-editor--mini.el-input__inner {
  width: 210px;
}
.demo-form-inline {
  padding-left: 2em;
}
</style>